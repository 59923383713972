


import React from 'react'

function Footer() {
  return (
    <div className='flex items-center p-5 justify-center dark:text-textDark text-textLight'>
      copyright©Montaser All right reserved 2022 
    </div>
  )
}

export default Footer
