import { useEffect, useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';
import { FaPhoneAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { BsLinkedin } from 'react-icons/bs';
import { FaGithubSquare } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import SocialItems from '../components/SocialItem';

const Contact = () => {
  const [ref, inView] = useInView({ threshold: 0.6 });
  useEffect(() => {
    if (inView) {
      document.getElementById('contact').classList.add('active');
    } else {
      document.getElementById('contact').classList.remove('active');
    }
  }, [inView]);

  const [formData, setFormData] = useState({
    user_name: '',
    user_email: '',
    message: '',
  });

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  const [loading, setLoading] = useState(false);

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (formData.user_email && formData.user_name && formData.message) {
      setLoading(true);
      emailjs
        .sendForm(
          'service_h7jvjnd',
          'template_leglsga',
          form.current,
          'VcGECIbw9DkP-Dmud'
        )
        .then(
          (result) => {
            toast.success('message sended');
            form.current.reset();
            setFormData({
              user_email: '',
              user_name: '',
              message: '',
            });
            setLoading(false);
          },
          (error) => {
            toast(error.text);
          }
        );
    } else {
      toast.error('all fields required');
    }
  };

  return (
    <section
      ref={ref}
      className="skill-container  h-screen"
      id="contactSection">
      <div className="contact-wrapper   gap-y-2  p-4">
        <div className="h-full basis-1/12 md:pl-3 w-full flex md:flex-col flex-row justify-between">
          <SocialItems
            Icon={BsLinkedin}
            type="social"
            href="https://www.linkedin.com/in/montaser-ahmed-95668b22b"
          />
          <SocialItems
            Icon={MdEmail}
            type="social"
            href="milto: mntsr9999.z@gmail.com"
          />

          <SocialItems Icon={FaPhoneAlt} type="phone" />
          <SocialItems
            Icon={FaGithubSquare}
            href="https://github.com/montasir34"
            type="social"
          />
        </div>
        <div className="dark:bg-cyan-600/50 basis-11/12 bg-cyan-900 rounded-md  w-full h-full">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="flex md:justify-between gap-y-4 flex-col p-4 h-full">
        
              <input
                onChange={handleChange}
                value={formData.name}
                className="contact-input"
                name="user_name"
                placeholder="Name"
                type="text"
              />
              <input
                onChange={handleChange}
                value={formData.email}
                className="contact-input"
                name="user_email"
                placeholder="Email"
                type="email"
              />
              <textarea
                value={formData.message}
                name="message"
                onChange={handleChange}
                className="outline-none  border-white/30 border-2 p-2
             text-white w-[70%] ring-offset-0 bg-transparent focus:ring-1 focus:ring-white rounded-md"
                placeholder="Type your message here"
                id=""
                rows="2"></textarea>
         

            <button
              disabled={loading}
              className="md:w-[20%] dark:hover:bg-white/60 rounded-md bg-white/60 dark:bg-white/50
            dark:text-textLight text-textLight p-1  ">
              SEND MESSAGE
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
