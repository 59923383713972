import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import amazon from '../img/amazon.png';
import linkedin from '../img/linkedin.png';
import portfolio from '../img/portfolio.png';
import delivery from '../img/delivery.png';
import ProjectCart from './ProjectCart';
const Project = () => {
  const [ref, inView] = useInView({ threshold: 0.6 });
  useEffect(() => {
    if (inView) {
      document.getElementById('projects').classList.add('active');
    } else {
      document.getElementById('projects').classList.remove('active');
    }
  }, [inView]);
  return (
    <section ref={ref} id="projectsSection" className="skill-container">
      <div
        className="skill-wrapper overflow-y-scroll scrollbar-hide grid p-4 gap-4
       grid-cols-1 md:grid-cols-2">
        <ProjectCart
          link={`https://amazon-clone-aqg.pages.dev`}
          img={amazon}
          title="Amazon"
          describe=" has complex layout"
        />
        <ProjectCart
          img={linkedin}
          link={`https://linkedin-clone-672.pages.dev`}
          title="Linkedin"
          describe="i love it"
        />
        <ProjectCart
          img={delivery}
          link={`https://food-delivery-acc10.web.app`}
          title="Delivery"
          describe="i love the food :)"
        />
        <ProjectCart
          img={portfolio}
          link={`https://portfolio-aj6.pages.dev`}
          title="Portfolio"
          describe="i consider it my interface"
        />
      </div>
      <div className="skill-light"></div>
    </section>
  );
};

export default Project;
