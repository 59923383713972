import Header from './components/Header';
import Degree from './components/Degree';
import Me from './sections/Me';
import { Routes, Route } from 'react-router-dom';
import Tool from './Outlets/Tool';
import Libraries from './Outlets/Libraries';
import Frameworks from './Outlets/Frameworks';
import Basics from './Outlets/Basics';
import Skill from './sections/Skill';
import Project from './sections/Project';
import Contact from './sections/Contact';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React from 'react';
import Footer from './components/footer';

function App() {
  
  const [isDarkMode, setDarkMode] = React.useState(JSON.parse(localStorage.getItem('theme')));

  const toggleDarkMode = (checked) => {
    setDarkMode(checked);
    localStorage.setItem('theme', JSON.stringify(checked));
  };

  // const toggleDarkMode = (checked) => {
  //   setDarkMode(checked);
  //   if (
  //     (isDarkMode && localStorage.theme === 'dark') ||
  //     (!('theme' in localStorage) &&
  //       window.matchMedia('(prefers-color-scheme: dark)').matches)
  //   ) {
  //     document.documentElement.classList.remove('dark');
  //   }
  // };

  // React.useEffect(() => {
  //   if (
  //     localStorage.theme === 'dark' ||
  //     (!('theme' in localStorage) &&
  //       window.matchMedia('(prefers-color-scheme: dark)').matches)
  //   ) {
  //     setDarkMode(true);
  //     document.documentElement.classList.add('dark');
  //   }
  // }, []);

  return (
    <div className={`font-[inter] ${isDarkMode ? 'dark' : null}  `}>
      <ToastContainer />
      <Header isDarkMode={isDarkMode} toggleDarkMode={toggleDarkMode} />
      <div className="dark:bg-backgroundDark">
        <Me />
        <Routes>
          <Route path="/" element={<Skill />}>
            <Route index element={<Basics />} />
            <Route path="base" element={<Basics />} />
            <Route path="frame" element={<Frameworks />} />
            <Route path="lib" element={<Libraries />} />
            <Route path="tool" element={<Tool />} />
          </Route>
        </Routes>

        <Project />
        <Contact />
        <Degree />
        <Footer />
      </div>
    </div>
  );
}

export default App;
