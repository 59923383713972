import React from 'react';
import Progress from '../components/Progress-circular';

function Libraries() {
  return (
    <div className="md:p-8 p-4 gap-y-4 justify-items-center grid  w-[100%] grid-cols-2  md:grid-cols-3">
      <Progress
       percentage={80}
        style={`w-[100px] h-[100px]`}
        img={`https://pagepro.co/blog/wp-content/uploads/2020/03/framer-motion.png`}
      />
      <Progress
       percentage={97}
        style={`w-[100px] h-[100px]`}
        img={`https://miro.medium.com/max/1838/1*sX8rBJBol5dBp5WIJQrYyw.png`}
      />
      <Progress
       percentage={90}
        style={`w-[100px] h-[100px]`}
        img={`https://seeklogo.com/images/R/redux-logo-9CA6836C12-seeklogo.com.png`}
      />
      <Progress
       percentage={91}
        style={`w-[100px] h-[100px]`}
        img={`https://iconape.com/wp-content/png_logo_vector/react-logo.png`}
      />
      <Progress
       percentage={80}
        style={`w-[100px] h-[100px]`}
        img={`https://seeklogo.com/images/T/tailwind-css-logo-5AD4175897-seeklogo.com.png`}
      />
      <Progress
       percentage={94}
        style={`w-[100px] h-[100px]`}
        img={`https://brandlogos.net/wp-content/uploads/2021/09/bootstrap-logo.png`}
      />
    </div>
  );
}

export default Libraries;
