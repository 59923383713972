import React from 'react';

function SocialItems({ Icon, title, type, href }) {
  return (
    <div>
      {type === 'social' ? (
        <a
          className=" gap-x-2 bg-white/80 hover:bg-textLight  group inline-block p-3 rounded-md shadow-lg
        text-textLight "
          href={href}>
          <Icon className="text-gray-800 group-hover:text-white text-2xl" />
          {title && title}
        </a>
      ) : (
        <div>
          <a
            className="  bg-white/80 p-3  hover:bg-textLight group inline-block rounded-md shadow-lg
          text-textLight "
            href='tel: +294 922 000 953'>
            <Icon className="text-gray-800 group-hover:text-white text-2xl" />
           
          </a>
        </div>
      )}
    </div>
  );
}

export default SocialItems;
