import React from 'react';
import { motion } from 'framer-motion';

function ProjectCart({ img, title, describe, link }) {
  return (
    <motion.div
      transition={{ duration: 2 }}
      className="w-full h-52 md:h-80 
    relative overflow-x-hidden
    transition-all shadow-lg
    duration-500  
     bg-white/40 group rounded-md">
      <div
        className="w-[30%] h-full md:group-hover:-translate-x-[100%]  
        group-hover:-translate-x-[100%] 
      absolute md:left-[100%] left-[100%] flex items-end
       transition-all duration-500 bg-gray-300 ">
        <div className="w-full h-full rounded-r-md px-4 md:py-5 pb-2 flex flex-col justify-between  bg-slate-600">
          <h2 className=' text-white'>{title}</h2>
          <div>
            <p className='text-gray-300 pr-3 md:text-sm text-xs w-[90%] max-w-prose'>" {describe} "</p>
          </div>

         <a  className="md:px-2 md:py-1 text-xs md:text-sm w-[100%] text-white font-[inter] font-bold rounded-md bg-cyan-600 hover:bg-white
          hover:text-cyan-600 text-center inline whitespace-nowrap md:inline-block " href={link}>Go live</a>
        </div> 
      </div>
      {img && <img className="object-fill w-full h-full" src={img} alt="" />}
    </motion.div>
  );
}

export default ProjectCart;
