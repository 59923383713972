import coursera from './Degree/Coursera.jpg';
import grass1 from './Degree/Grass1.png';
import grass2 from './Degree/Grass2.png';
import php from './Degree/PHP_certificate.jpg';
import react from './Degree/react_certificate.jpg';
import responsive from './Degree/Responsive.jpg';

export const degrees = [
  {
    id: 1,
    img: coursera,
  },
  {
    id: 2,
    img: grass1,
  },
  {
    id: 3,
    img: grass2,
  },
  {
    id: 4,
    img: php,
  },
  {
    id: 5,
    img: react,
  },
  {
    id: 6,
    img: responsive,
  },

];
