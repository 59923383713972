import { useEffect } from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import { useInView } from 'react-intersection-observer';
const Skill = () => {
  const [ref, inView] = useInView({ threshold: 0.6 });
  useEffect(() => {
    if (inView) {
      document.getElementById('skills').classList.add('active');
    } else {
      document.getElementById('skills').classList.remove('active');
    }
  }, [inView]);
  return (
    <section ref={ref} id="skillsSection" className="skill-container">
      <div className="skill-wrapper">
        <ul className="skill-category">
          <li>
            <NavLink id='link' className={({isActive})=> isActive ? 'skill-active' : ''} to="" end>Basics</NavLink>
          </li>
          <li>
            <NavLink id='link' className={({isActive})=> isActive ? 'skill-active' : ''} to="frame">Others</NavLink>
          </li>
          <li>
            <NavLink id='link' className={({isActive})=> isActive ? 'skill-active' : ''} to="lib">Libraries</NavLink>
          </li>
          <li>
            <NavLink id='link' className={({isActive})=> isActive ? 'skill-active' : ''} to="tool">Tools</NavLink>
          </li>
        </ul> 
        
        <div className="skill-content">
         <Outlet />
        </div>
      </div>
      <div className="skill-light"></div>
    </section>
  );
};

export default Skill;
