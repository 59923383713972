import React from 'react';
import {motion} from 'framer-motion'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import ProgressProvider from './ProgressProvider';

function Progress({icon , img, style, percentage}) {
  
  return (
    <motion.div initial={{opacity:0, scale: 0}} animate={{opacity:2, scale: 1}} className="bg-white/25 w-[90%]   space-y-2 flex 
     md:flex-row flex-col gap-y-4 md:gap-y-0 items-center justify-between transition-all
     duration-700 md:w-60 shadow-xl rounded-md p-2 md:p-3 ">
      <div className='md:w-[100px] m-1 md:h-[100px] h-[70px] w-[50%]'>
        <ProgressProvider valueStart={0} valueEnd={percentage}>
          {(value) => (
            <CircularProgressbar
              text={`${value}%`}
              value={value}
              styles={buildStyles({
                // // Rotation of path and trail, in number of turns (0-1)
                // rotation: 0.25,

                // // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                // strokeLinecap: 'butt',

                // // Text size
                // textSize: '16px',

                // // How long animation takes to go from one percentage to another, in seconds
                // pathTransitionDuration: 0.5,

                // // Can specify path transition in more detail, or remove it entirely
                // // pathTransition: 'none',

                // // Colors
                pathColor: 'rgb(255, 255, 255)',
                textColor: '#000033',
                trailColor: '#9999ff',
                backgroundColor: '#ccffcc',
              })}
            />
          )}
        </ProgressProvider>
      </div>
      <div>
         {icon && icon}
             
              {img && <img className={`${style}`} src={img} alt="" />}
        </div>
    </motion.div>
  );
}

export default Progress;
