import me from '../img/Toon.png';
import { useInView } from 'react-intersection-observer';
import HumbergerMenu from '../components/HumbergerMenu';
import montaser from '../Degree/MONTASER_FULL-STACK.pdf'

import React from 'react';
const Me = () => {
  const [ref, inView] = useInView({ threshold: 0.6 });

  React.useEffect(() => {
    if (inView) {
      document.getElementById('me').classList.add('active');
    } else {
      document.getElementById('me').classList.remove('active');
    }
  }, [inView]);

  return (
    <section ref={ref} className="me-container" id="meSection">
      <div className="me-child1">
        <h1>Hi !</h1>
        <h1>I am Montaser</h1>
        <h1>Full Stack Developer</h1>
        <p className="text-lg text-gray-400">
          Since i was young i have passionate about technology <br />
          and i have developed this passion a lot and i always try to do my best
          <br />
          and i love to share my ideas and wish to exprience teamwork <br />
          and always look forward to keeping up <br /> all i wish is to leave my
          mark on this world
          <br /> <strong>so let's keep building</strong>
        </p>
        <a href={montaser} download className='hire w-40 text-center mx-auto md:mx-0 p-2 md:w-40'>download Cv</a>
      </div>
      <div className="me-child2">
        <HumbergerMenu />
        <img
          className=" gradient-mask-b-20 rounded-full  md:h-[450px]"
          src={me}
          alt=""
        />
      </div>
    </section>
  );
};

export default Me;
