import React from 'react';
import coursera from '../Degree/Coursera.jpg'
import { degrees } from '../degree';
import { motion } from 'framer-motion';

function Degree() {
  const [selectedId, setSelectedId] = React.useState( {
    id: 1,
    img: coursera,
  });

  return (
    <div className="skill-container">
      <div className="degree-wrapper">
        <div className="md:w-[20%] md:h-full h-[30%] gap-x-4 w-full md:flex-col flex flex-row 
        scrollbar-hide md:space-y-4 overflow-y-scroll">
          {degrees.map((item) => (
            <img
            className='md:rounded-md'
              key={item.id}
              onClick={() => setSelectedId(item)}
              src={item.img}
              alt=""
            />
          ))}
        </div>

    {selectedId && <div className="degree-cart">
          <div className="group">
            <img className='h-full w-full rounded-md' src={selectedId.img} alt="" />
            {/* <a href='#' className="title-degree">Go pdf</a> */}
          </div>
          
        </div>}
      </div>
    </div>
  );
}

export default Degree;
