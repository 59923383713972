import React from 'react';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';

import Hamburger from 'hamburger-react';

function HumbergerMenu() {
  const [isOpen, setOpen] = useState(false);
  useEffect(() => {}, [isOpen]);
  const navLinks = ['Me', 'Skills', 'Projects', 'Contact'];

  const renderNavLink = (content) => {
    const scrollToId = `${content.toLowerCase()}Section`;

    const handleClickNav = () => {
      document
        .getElementById(scrollToId)
        .scrollIntoView({ behavior: 'smooth' });
    };

    return (
      <ul key={content}>
        <li className="">
          <motion.button
      
            className="relative"
            id={content.toLowerCase()}
            onClick={handleClickNav}>
            {content}
          </motion.button>
        </li>
      </ul>
    );
  };
  return (
    <div className='fixed md:hidden -top-4 z-50 right-0'>
      <div className="absolute z-40 dark:bg-backgroundDark bg-white top-20 flex right-0">
        <Hamburger toggled={isOpen} toggle={setOpen} />
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
          
            initial={{ translateX: 100 }}
            animate={{ translateX: 50 }}
            exit={{ translateX: 600 }}
           
            className="absolute top-20 w-[500px] right-8 p-3 overflow-hidden 
             justify-end flex gap-x-5
             dark:bg-backgroundDark bg-white px-20 ">
            {navLinks.map((nav) => renderNavLink(nav))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default HumbergerMenu;
