import { DarkModeSwitch } from 'react-toggle-dark-mode';
import montaser from '../Degree/MONTASER_FULL-STACK.pdf'
import { motion } from 'framer-motion';
const Header = ({ isDarkMode, toggleDarkMode }) => {
  const navLinks = ['Me', 'Skills', 'Projects', 'Contact'];
  const handleClickLogo = () => {
    document.getElementById('meSection').scrollIntoView({ behavior: 'smooth' });
  };

  const renderNavLink = (content) => {
    const scrollToId = `${content.toLowerCase()}Section`;

    const handleClickNav = () => {
      document
        .getElementById(scrollToId)
        .scrollIntoView({ behavior: 'smooth' });
    };

    return (
      <ul key={content}>
        <li className="menu-child">
          <motion.button  className='relative dark:text-textDark text-textLight' id={content.toLowerCase()} onClick={handleClickNav}>{content}</motion.button>
        </li>
      </ul>
    );
  };

  return (
    <div  className="header-container">
      <div className="nav-container">
        <div className="logo-container">
          <h1 onClick={handleClickLogo} className="logo">
            Montaser.
          </h1>
        </div>
        <DarkModeSwitch
          checked={isDarkMode}
          onChange={toggleDarkMode}
          size={30}
        />
        <nav className="menu">{navLinks.map((nav) => renderNavLink(nav))}</nav>
        <a href={montaser} download className="hire">download cv</a>
      </div>
    </div>
  );
};

export default Header;
